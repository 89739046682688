<template>
  <div class="notification-wrapper" v-show="loginStatus && showNotification">
    <svg-icon
      iconClass="notice"
      className="notice_icon"
      @click="handleClickNotification"
      data-testid="notice"
    ></svg-icon>
    <Notice :notificationVisible.sync="notificationVisible" :notifications="notifications"></Notice>
  </div>
</template>

<script>
import { apiGetNotification } from '@/resource'
import Notice from '@/components/notification/Notice'

export default {
  name: 'HeaderNotification',
  components: { Notice },
  props: {},
  data() {
    return {
      notificationVisible: false,
      showNotification: true,
      notifications: [
        {
          subject: '',
          status: 1,
          country: 'All',
          displayType: '',
          notificationDocuments: [],
        },
      ],
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang
    },
    loginStatus() {
      return this.$store.state.common.loginStatus
    },
    autoNotifyStatus() {
      return this.$store.state.common.autoNotifyStatus
    },
    enabledNoticeTitle() {
      if (this.notifications.length < 1 || this.notifications[0].notificationDocuments.length < 1) {
        return ''
      }

      let currentLangNoticeDoc = this.notifications[0].notificationDocuments.find(
        doc => doc.language_code === this.lang,
      )
      if (!currentLangNoticeDoc || currentLangNoticeDoc.is_del === 1) {
        currentLangNoticeDoc = this.notifications[0].notificationDocuments.find(doc => doc.language_code === 'en_US')
      }
      return currentLangNoticeDoc.subject
    },
  },
  watch: {
    loginStatus(loginStatus) {
      if (loginStatus) this.getNotification(false)
    },
  },
  mounted() {
    if (this.loginStatus) this.getNotification(this.autoNotifyStatus)
  },
  methods: {
    handleDecoding(str) {
      return str
        .replace(/amp;/gi, '')
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&quot;/gi, '"')
        .replace(/&nbsp;/gi, ' ')
    },
    disableNotification() {
      this.showNotification = false
    },
    handleClickNotification() {
      this.notificationVisible = true
    },
    getNotification(isShow) {
      apiGetNotification()
        .then(res => {
          if (res.data.data.length === 0) {
            this.disableNotification()
          } else {
            this.notifications = res.data.data
              .slice()
              .sort((a, b) => a.priority - b.priority)
              .map(e => {
                e.notificationDocuments.map(doc => this.handleDecoding(doc.document))
                return e
              })
            if (this.notifications[0].announcementDisplayType === 0 && isShow) {
              this.$store.commit('common/setAutoNotifyStatus', false)
              this.notificationVisible = true
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.showNotification = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.notice_icon {
  font-size: 20px;
  cursor: pointer;
  margin: 0 8px;
}
</style>
