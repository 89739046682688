<template>
  <el-container :key="reRender" class="wrapper">
    <el-aside :width="!matches ? center : ''">
      <slideBar :isCollapse.sync="isCollapse" :matches="matches" :onOpen.sync="onOpen" @matchesClose="matchesClose">
      </slideBar>
    </el-aside>
    <el-container class="is-vertical">
      <vHeader :matches="matches" :onOpen.sync="onOpen" :isCollapse.sync="isCollapse" @openMenu="openMenu"></vHeader>
      <div id="elMain">
        <transition>
          <router-view></router-view>
        </transition>
      </div>
    </el-container>
    <SessionDialog />
  </el-container>
</template>

<script>
import vHeader from '@/components/template/header/Header'
import slideBar from '@/components/Menu'
import SessionDialog from '@/components/SessionDialog.vue'
import { apiSetlanguage } from '@/resource'

export default {
  name: 'layout',
  data() {
    return {
      reRender: 0,
      center: '240px',
      matches: false,
      smallMatches: false,
      isCollapse: false,
      onOpen: true,
      sliderBarWidth: 0,
      headerWidth: 0,
    }
  },
  components: { vHeader, slideBar, SessionDialog },
  computed: {
    lang() {
      return this.$store.state.common.lang
    },
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        if (this.$store.state.common.loginStatus) this.setUserLanguagePreference(val)
        this.reRender++
      },
    },
    reRender(reRender) {
      if (this.$route.meta.restrictReload) this.$router.push('/home')
    },
    onOpen(bool) {
      this.isCollapse = !bool
    },
    isCollapse(bool) {
      if (!bool && this.onOpen) {
        this.center = '240px'
      } else if (!this.matches) {
        this.center = '64px'
      } else {
        this.center = '0'
      }
    },
    matches(bool) {
      if (bool) {
        this.onOpen = false
        this.center = '0'
        this.isCollapse = false
      } else {
        this.onOpen = true
        this.center = '240px'
      }
    },
    smallMatches(bool) {
      if (bool) {
        this.onOpen = false
      }
    },
    $route: {
      handler: function(route) {
        this.$nextTick(() => {
          const elMain = document.getElementById('elMain')
          elMain.scrollTop = route.path.indexOf('deposit/') != -1 ? 250 : 0
        })
      },
      immediate: true,
    },
  },
  mounted() {
    const vm = this
    vm.checkSize()
    $(window).resize(function() {
      vm.checkSize()
    })
  },
  methods: {
    checkSize() {
      let w = window.innerWidth

      if (w <= 1023 && w >= 768) {
        this.smallMatches = window.matchMedia('(max-width: 1023px)').matches
        this.matches = false
      } else if (w < 768) {
        this.matches = window.matchMedia('(max-width: 768px)').matches
        this.smallMatches = false
      } else {
        this.matches = false
        this.smallMatches = false
      }
    },
    setUserLanguagePreference(lang) {
      apiSetlanguage({ language: lang })
    },
    openMenu() {
      this.isCollapse = !this.isCollapse
      this.onOpen = !this.onOpen
    },
    matchesClose() {
      this.onOpen = !this.onOpen
      this.isCollapse = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/app.scss';

.v-leave {
  opacity: 1;
}

.v-leave-active {
  transition: opacity 0.5s;
}

.v-leave-to {
  opacity: 0;
}

.v-enter {
  opacity: 0;
}

.v-enter-active {
  transition: opacity 0.2s;
}

.v-enter-to {
  opacity: 1;
}
</style>
