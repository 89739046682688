export default {
  computed: {
    idPass() {
      return this.$store.state.common.idPass
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    openAccountEnabled() {
      return this.$store.state.common.openAccountEnabled
    },
    openAdditionAccountEnabled() {
      return (
        this.openAccountEnabled &&
        this.idPass &&
        !this.$config.restrictOpenAdditionAccountCountries(this.regulator).includes(parseInt(this.countryCode))
      )
    },
  },
}
