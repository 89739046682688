<template>
  <s-dialog :visible.sync="visible" :title="$t('sessionDialog.title')" cancelButtonTestId="keepIn"
    confirmButtonTestId="signOut" :cancelButtonText="$t('sessionDialog.keepIn')"
    :confirmButtonText="$t('sessionDialog.signOut')" @cancel="keepIn" @confirm="signOut">
    <!-- <template #body> -->
    <!-- <el-row type="flex"> -->
    <!-- <i class="el-icon-warning"></i> -->
    <!-- <div>{{ $t('sessionDialog.content') }}</div> -->
    <!-- <StatusMessage type="success" :title="$t('sessionDialog.content')"> </StatusMessage> -->

    <StatusMessage type="success" class="status_session">
      <template #content>
        <p class="session_content">{{ $t('sessionDialog.content') }}</p>
      </template>
    </StatusMessage>
    <!-- </el-row> -->
    <!-- </template> -->

    <!-- <template #btn>
      <el-row type="flex" justify="end">
        <el-button class="btn-blue" size="small" @click="keepIn" data-testid="keepIn">{{
          $t('sessionDialog.keepIn')
        }}</el-button>
        <el-button class="btn-default" size="small" plain @click="signOut" data-testid="signOut">{{
          $t('sessionDialog.signOut')
        }}</el-button>
      </el-row>
    </template> -->
  </s-dialog>
</template>

<script>
import { setCookies, getCookies, getExpiresTime } from '@/util/cookies';
import { minusTime } from '@/util/time';
import helper from '@/util/signinHelper';

export default {
  data() {
    return {
      visible: false,
      alertSec: 60 * 1000,
      countDown: null,
      totalTime: null,
      signOutTimer: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setTime(this.getTotalTime() - this.alertSec, this.timeoutFunc);
    },
    timeoutFunc() {
      this.countDown = 60;
      this.signOutTimer = this.setInter(1000, this.intervalFunc);
      this.visible = true;
    },
    intervalFunc() {
      this.countDown >= 0 ? this.countDown-- : this.signOut();
    },
    setTime(time, func) {
      return setTimeout(() => {
        func();
      }, time);
    },
    setInter(time, func) {
      return setInterval(() => {
        func();
      }, time);
    },
    getTotalTime() {
      return minusTime(new Date(), getExpiresTime('token'));
    },
    keepIn() {
      if (!getCookies('token')) return this.signOut();

      const token = getCookies('token');
      setCookies('token', token, 30);
      this.setTime(this.getTotalTime() - this.alertSec, this.timeoutFunc);
      clearInterval(this.signOutTimer);
      this.visible = false;
    },
    signOut() {
      this.visible = false;
      clearInterval(this.signOutTimer);
      helper.signOut();
    },
  },
};
</script>
<style lang="scss" scoped>
.status_session {
  display: flex;
}
</style>
