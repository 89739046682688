<template>
  <div id="menu">
    <div class="menu-shadow" v-show="matches && onOpen" @click="matchesClose" data-testid="menu_shadow"></div>
    <div class="menu-wrapper" :style="menu_wrapperStyle">
      <div class="menu_logo" v-if="(!isCollapse || matches) && onOpen">
        <router-link to="/home" class="logo">
          <img src="@/assets/images-1/light_logo_icon.png" v-if="matches" class="logo_img_small" />
          <img alt src="@/assets/images-1/unfolded_logo.png" v-else class="logo-img" />
        </router-link>
        <svg-icon v-if="matches" @click="matchesClose" class="menu_back" data-testid="menu_back"
          iconClass="menu_close"></svg-icon>
      </div>
      <div class="menu_logo_small" v-else>
        <router-link to="/home" class="logo">
          <img alt class="logo-img" src="@/assets/images-1/light_logo_icon.png" />
        </router-link>
      </div>
      <el-scrollbar style="height: 100%">
        <!-- <el-collapse-transition> -->
        <el-menu class="el-menu-vertical-demo" background-color="#1C214F" text-color="#ffffff" active-text-color="#5AD6CF"
          :unique-opened="true" :default-active="$route.path" :collapse="isCollapse && !matches">
          <div v-for="(menu, index) in getMenus" v-bind:key="index">
            <el-menu-item :index="checkHomeUrl(menu.path)" v-if="checkRegister(menu)" @click="clickMenu(menu)"
              :data-testid="menu.name">
              <svg-icon :iconClass="menu.icon"></svg-icon>
              <span slot="title" class="title" @click="menuClick(menu.path)">{{ $t(menu.name) }}</span>
            </el-menu-item>
            <el-submenu :index="menu.path" v-if="menu.children" :data-testid="menu.name">
              <template slot="title">
                <div class="title_box">
                  <svg-icon :iconClass="menu.icon"></svg-icon>
                  <span class="title">{{ $t(menu.name) }}</span>
                </div>
              </template>
              <template v-for="(children, index) in menu.children">
                <el-menu-item v-bind:key="index" :index="children.path" v-if="isOptional(children)"
                  @click="clickMenu(children)" :data-testid="children.name">
                  <span class="menu_item_icon"></span>
                  <span class="title">{{ $t(children.name) }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
          </div>
        </el-menu>
        <!-- </el-collapse-transition> -->
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import openAccountMixin from '@/mixins/openAccount'
import blackList from '@/mixins/blackList'
// import { PROMOTION_TYPE_PATH_MAP, PROMOTION_TYPE, SAME_PROMOTION_TYPE } from '@/constants/promotion'

// function filterPromotion(menu, campaignEligibilityPath) {
// for (const [index, item] of menu.entries()) {
//   if (item.name === 'menu.promotion') {
//     menu = [...menu]
//     menu[index].children = menu[index].children.filter(({ path }) => campaignEligibilityPath.includes(path))
//     return menu
//   }
// }
// return menu
// }

export default {
  name: 'vMenu',
  data() {
    return { bodyDirection: null }
  },
  mixins: [openAccountMixin, blackList],
  props: {
    isCollapse: Boolean,
    matches: Boolean,
    onOpen: Boolean,
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir
    })
  },
  methods: {
    matchesClose() {
      this.$emit('matchesClose')
    },
    checkMatchesClose() {
      const vm = this
      this.$nextTick(function () {
        if (vm.matches) vm.matchesClose()
      })
    },
    clickMenu(children) {
      if (!children.hasOwnProperty('checkBlacklist')) {
        this.checkMatchesClose()
        //https://github.com/vuejs/vue-router/issues/2872
        this.$router.push({ path: children.path }).catch(err => { })
      } else {
        if (children.path === '/depositFunds') this.getDepositBlacklist()
        else if (children.path === '/transferFunds') this.goToTransferFunds()
        else this.getWithdrawalBlacklist()
      }
    },
    isOptional(children) {
      if (!children.hasOwnProperty('optional')) return children
      else {
        // openAccountMixin
        if (this.openAdditionAccountEnabled) return children
      }
    },
    checkRegister(menu) {
      return (menu.path != '/register' || this.registerStep < 6) && !menu.children
    },
    checkHomeUrl(path) {
      return path == '/home' && this.registerStep == 1 && this.isDemo ? '/homeDemo' : path
    },
    menuClick(url) {
      if (this.isCollapse) this.$router.push({ path: url })
    },
    // filterPromotionTypePath() {
    //   return this.campaignEligibility.map(function (campaign) {
    //     return campaign.campaignType === PROMOTION_TYPE.DEPOSIT_BONUS
    //       ? SAME_PROMOTION_TYPE[PROMOTION_TYPE.DEPOSIT_BONUS][campaign.id]
    //       : PROMOTION_TYPE_PATH_MAP[campaign.campaignType]
    //   })
    // },
  },
  computed: {
    getMenus() {
      // const noCampaignEligibility = () => this.campaignEligibility.length === 0
      // if (noCampaignEligibility()) {
      //   return this.$config.getMenus(this.regulator).filter(menu => menu.name !== 'menu.promotion')
      // } else {
      //   return filterPromotion(this.$config.getMenus(this.regulator), this.filterPromotionTypePath())
      // }
      return this.$config.getMenus(this.regulator)
    },
    registerStep() {
      return this.$store.state.common.registerStep
    },
    isDemo() {
      return this.$store.state.common.isDemo
    },
    menu_wrapperStyle() {
      const menuWidth = { width: this.matches == false && this.isCollapse ? '64px' : '240px' }
      const direction = this.bodyDirection === 'rtl' ? { right: this.menuPosition } : { left: this.menuPosition }
      return { ...direction, ...menuWidth }
    },
    menuPosition() {
      return this.matches && !this.onOpen ? '-240px' : '0px'
    },
    // campaignEligibility() {
    //   return this.$store.state.promotion.eligibleCampaigns
    // },
  },
}
</script>

<style lang="scss" scoped>
$active_bg: #0e1236;

#menu,
.menu-shadow,
.menu-wrapper {
  transition: all 0.5s;
}

.menu-shadow {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: fixed;
  @include rtl-sass-prop(left, right, 0);
  @include rtl-sass-prop(right, left, unset);
  top: 0;
  z-index: 100;
}

.menu-wrapper {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  background-color: #1c214f;

  .menu_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    padding: 0 16px;
    background-color: #1c214f;

    .logo {
      display: inline-block;
      width: 190px;
      height: 32px;

      /**firefox**/
      .logo-img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .logo_img_small {
        width: 32px;
        height: 32px;
      }
    }

    .menu_back {
      width: 20px;
      height: 20px;
      color: $white;
      cursor: pointer;
    }
  }

  .menu_logo_small {
    height: 65px;
    text-align: center;
    background-color: #1c214f;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}

.el-menu-vertical-demo {
  border: none;

  .common_menu_item_style {
    width: 224px;
    box-sizing: border-box;
    padding: 0px 8px !important;
    margin: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    min-height: 56px;
    white-space: break-spaces;
  }

  .el-menu-item {
    @extend .common_menu_item_style;

    .svg-icon,
    .menu_item_icon {
      width: 24px;
      height: 24px;
      @include rtl-sass-prop(margin-right, margin-left, 4px);
    }

    span {
      font-size: 16px;
      line-height: normal;
      text-transform: uppercase;
    }

    &.is-active {
      background-color: $active_bg !important;
      font-weight: bold;
    }

    &:hover,
    &:active {
      background-color: $active_bg;
    }
  }

  /deep/ .el-submenu {
    .el-submenu__title {
      @extend .common_menu_item_style;

      .title_box {
        width: calc(100% - 18px);
        display: flex;
        align-items: center;

        .svg-icon {
          flex: 0 0 24px;
          height: 24px;
          @include rtl-sass-prop(margin-right, margin-left, 4px);
          // margin-right: 4px;
        }

        .title {
          width: 100%;
          font-size: 16px;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      .el-submenu__icon-arrow {
        font-size: 16px;
        color: $white;
        right: 8px;
      }
    }

    .el-menu {
      // height: auto !important;
      padding: 4px 0 !important;
      background-color: #04071c !important;

      .el-menu-item {
        background-color: #04071c !important;
        height: auto;
        line-height: normal;
        min-height: 40px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          font-size: 14px;
        }

        &.is-active {
          background-color: $active_bg !important;
          font-weight: bold;
        }

        &:hover,
        &:active {
          background-color: $active_bg !important;
        }
      }
    }

    &.is-active {
      .el-submenu__title {
        span {
          color: $primary;
          font-weight: bold;
        }

        .svg-icon {
          color: #5ad6cf;
        }
      }
    }
  }

  /deep/ &.el-menu--collapse {
    width: 100%;

    .el-menu-item {
      width: 100%;
      padding: 0;
      margin: 0;

      .el-tooltip {
        display: flex !important;
        align-items: center;
        justify-content: center;

        .svg-icon {
          margin: 0;
        }
      }
    }

    .el-submenu {
      .el-submenu__title {
        width: 100%;
        margin: 0;
        padding: 0 !important;

        .title_box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .svg-icon {
            margin: 0;
          }

          .title {
            display: none;
          }
        }

        >span,
        .el-submenu__icon-arrow {
          display: none;
        }

        .svg-icon {
          margin: 0;
        }
      }
    }
  }
}
</style>
