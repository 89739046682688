<template>
  <SDialog
    :visible.sync="visible"
    :title="notificationsItem.subject"
    @close="close"
    :showFooter="notifications.length > 0"
  >
    <div v-html="notificationsItem.document"></div>
    <Empty v-if="notifications.length === 0"></Empty>
    <template slot="footer">
      <el-row style="width: 100%;" type="flex" justify="center">
        <el-pagination
          background
          :pager-count="5"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total"
          :page-size="1"
          :current-page="currentPage"
        >
        </el-pagination>
      </el-row>
    </template>
  </SDialog>
</template>

<script>
export default {
  name: 'notice',
  props: {
    notificationVisible: Boolean,
    notifications: Array,
  },
  data() {
    return {
      visible: false,
      currentPage: 1,
    }
  },
  watch: {
    notificationVisible(bool) {
      this.visible = bool
    },
    visible(bool) {
      this.$emit('update:notificationVisible', bool)
    },
  },
  methods: {
    close() {
      this.currentPage = 1
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
  },
  computed: {
    lang() {
      return this.$store.state.common.lang
    },
    notificationsItem() {
      const currentNotification = this.notifications[this.currentPage - 1]
      if (!currentNotification) {
        return {
          subject: '',
          document: '',
        }
      }
      let currentLangNoticeDoc = currentNotification.notificationDocuments.find(doc => doc.language_code === this.lang)
      if (!currentLangNoticeDoc || currentLangNoticeDoc.is_del === 1) {
        currentLangNoticeDoc = currentNotification.notificationDocuments.find(doc => doc.language_code === 'en_US')
      }
      if (!currentLangNoticeDoc) {
        return {
          subject: '',
          document: '',
        }
      }
      return {
        subject: currentLangNoticeDoc.subject,
        document: currentLangNoticeDoc.document,
      }
    },
    total() {
      return this.notifications.length
    },
  },
}
</script>

<style lang="scss" scoped></style>
